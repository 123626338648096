import { action, computed, observable } from 'mobx';

import { Stores } from '@src/stores';
import UserObject, {
  AdminBasicUserObject,
  CollaboratorStatus,
  CurrentUserObject,
  DefaultRates,
  UserBadge,
  UserScrubbed,
  UserStatus,
} from '@a_team/models/dist/UserObject';
import AdminNotesObject, {
  AdminNoteId,
} from '@a_team/models/dist/AdminNotesObject';
import {
  apiExperiences,
  apiMissions,
  apiReferrals,
  apiTalentCategories,
  apiTalentSpecializations,
  apiUser,
  apiUsers,
  apiVettingProcess,
} from '@ateams/api';
import {
  DESIGNER_CATEGORY_ID,
  SOFTWARE_ENGINEER_CATEGORY_ID,
} from '@src/config';

import {
  ExperienceId,
  ExperienceMember,
  ExperienceMemberRole,
  ExperienceSource,
  ExperienceType,
} from '@a_team/models/dist/ExperienceObject';
import LocationObject from '@a_team/models/dist/LocationObject';
import { UserExperienceData } from '@ateams/api/dist/endpoints/User';
import type { PersonalInfoData } from '@ateams/api/dist/endpoints/User';
import { AdminNotesRequestPayload } from '@ateams/api/dist/endpoints/Users';
import {
  getJobId,
  getProjectId,
  getProjectMemberId,
  isEmptyProject,
  isNewExperienceItem,
  isPlaceholderProject,
  isProjectMemberUser,
} from '@src/views/Profile/helpers/experience';
import { generateUniqueId } from '@src/helpers/strings';
import { BasicConnectionObjectV2 } from '@a_team/models/dist/ConnectionObject';
import { isUndefined, orderBy, sortBy } from 'lodash';
import Application, {
  ApplicationStoreData,
  ApplicationViewMode,
} from '@src/stores/Application';
import AdminNotes, { AdminNotesStoreData } from '@src/stores/AdminNotes';
import MissionApplicationObject, {
  MissionApplicationAnalysisObject,
  MissionApplicationDraftObject,
} from '@a_team/models/dist/MissionApplicationObject';
import {
  AvailabilityUpdateSource,
  PlatformServiceAnalytics,
  ProfileUpdateSource,
} from '@ateams/analytics/dist/platform';
import {
  AvailableType,
  ReminderPeriod,
} from '@a_team/models/dist/AvailabilityObject';
import type { AvailabilityData } from '@a_team/models/dist/AvailabilityObject';
import type { AvailabilitySummaryObject } from '@a_team/models/dist/AvailabilityObject';
import { add, formatISO, isBefore, parseISO, setHours } from 'date-fns';
import { getReminderPeriod } from '@src/helpers/availability';
import {
  TalentCategory,
  TalentCategoryId,
  TalentProfile,
  TalentSpecialization,
  UserTalentSkillAssignmentData,
} from '@a_team/models/dist/TalentCategories';
import {
  expertiseToSkill,
  roleToExpertise,
  skillToExpertise,
} from '@src/helpers/expertise';
import {
  ApplicationDetails,
  CTA,
  ExistingJob,
  ExistingProject,
  Expertise,
  LocalJobs,
  LocalRoles,
  LocalSkills,
  LocalWebsites,
  NewJob,
  NewProject,
  ProfileViewMode,
  Website,
} from '@src/stores/Profile/models';
import type { LocalProjects } from '@src/stores/Profile/models';
import type { VetterConfigurationObject } from '@a_team/models/dist/vetter';
import TimezoneObject from '@a_team/models/dist/TimezoneObject';
import { Company } from '@a_team/models/dist/Company';
import { CDN_BASE_URL } from '@src/config';
import isEqual from 'lodash/isEqual';
import {
  SendEvaluationInvitationPayload,
  StartVettingProcessPayload,
} from '@ateams/api/dist/endpoints/vetting-process';
import VetterConfiguration, {
  VetterConfigurationStoreData,
} from '../VetterConfiguration';
import MissionObject from '@a_team/models/dist/MissionObject';
import { WorkingHoursSchema } from '@a_team/models/dist/WorkingHoursObject';
import { CustomUserTagObject } from '@a_team/models/dist/CustomUserTagObject';
import PortfolioObject from '@a_team/models/dist/PortfolioObject';
import { ScrubReason } from '@a_team/models/src/UserObject';
import { fetchIndustryList } from '@src/helpers/talent-industry-api';
import { fetchSkillList } from '@src/helpers/talent-skills-api';
import { RawCountry, rawCountryList } from '@src/helpers/rawCountryList';
import { isValidGithubUrl, isValidLinkedInUrl } from '@src/helpers/urls';
import { NewCompanyId } from '@src/components/CompanyPicker';
import { filterOutBracketedSkills } from '../utils';

const GENERAL_TALENT_CATEGORY_TEXTID = 'general';
export const MAX_SELECTED_PROJECTS = 3;

export type SerializedProfileStoreData = Omit<
  ProfileStoreData,
  'adminNotes' | 'application' | 'vetterConfiguration'
> & {
  application?: ApplicationStoreData;
  adminNotes?: AdminNotesStoreData;
  vetterConfiguration?: VetterConfigurationStoreData;
};

interface ProfileSectionSavingInfo {
  condition: boolean;
  action: () => Promise<void>;
}

export interface ProfileStoreData {
  data: UserObject;
  mode: ProfileViewMode;
  yearsOfExperience?: number;
  location?: LocationObject;
  timezone?: TimezoneObject;
  workingHours?: WorkingHoursSchema;
  phone?: string;
  aboutMe?: string;
  jobs: LocalJobs;
  projects: LocalProjects; // Includes deleted projects
  websites: LocalWebsites;
  skills: LocalSkills;
  mainRole?: Expertise;
  roles: LocalRoles;
  generalTalentCategory?: TalentCategory;
  industryExperiences: Expertise[];
  showErrors: boolean;
  application?: Application;
  adminNotes?: AdminNotes;
  vetterConfiguration?: VetterConfiguration;
  availability?: AvailabilitySummaryObject;
  selectedAvailabilityReminderPeriod?: ReminderPeriod;
  isTeamGraphPreview: boolean | null;
  profileUpdatedAt?: Date | null;
  portfolio?: PortfolioObject;
  scrubbedMinusOneReasons?: ScrubReason[];
  showConfirmSubmitEdits: boolean;
  readonly: boolean;
  linkedinUsername?: string;
  githubUsername?: string;
  defaultRates?: DefaultRates;
  submitEditsLater?: boolean;

  highlightedAdminNoteId?: AdminNoteId;
}

export interface UserCV {
  label: string;
  downloadUrl: string | undefined;
}

export default class Profile implements ProfileStoreData {
  @observable data: ProfileStoreData['data'];
  @observable mode: ProfileStoreData['mode'] = ProfileViewMode.View;
  @observable location: ProfileStoreData['location'];
  @observable yearsOfExperience: ProfileStoreData['yearsOfExperience'];
  @observable phone: ProfileStoreData['phone'];
  @observable aboutMe: ProfileStoreData['aboutMe'];
  @observable jobs: ProfileStoreData['jobs'] = [];
  @observable projects: ProfileStoreData['projects'] = [];
  @observable websites: ProfileStoreData['websites'] = [];
  @observable skills: ProfileStoreData['skills'] = { main: [], additional: [] };
  @observable portfolio: ProfileStoreData['portfolio'];
  @observable roles: ProfileStoreData['roles'] = [];
  @observable mainRole: ProfileStoreData['mainRole'];
  @observable showErrors: ProfileStoreData['showErrors'] = false;
  @observable
  showConfirmSubmitEdits: ProfileStoreData['showConfirmSubmitEdits'] = false;
  @observable application: ProfileStoreData['application'];
  @observable adminNotes: ProfileStoreData['adminNotes'];
  @observable vetterConfiguration: ProfileStoreData['vetterConfiguration'];
  @observable availability: ProfileStoreData['availability'];
  @observable generalTalentCategory: ProfileStoreData['generalTalentCategory'];
  @observable
  availabilityReminderPeriod: ProfileStoreData['selectedAvailabilityReminderPeriod'];
  @observable
  isTeamGraphPreview: ProfileStoreData['isTeamGraphPreview'] = null;
  @observable timezone: ProfileStoreData['timezone'];
  @observable workingHours: ProfileStoreData['workingHours'];
  @observable industryExperiences: ProfileStoreData['industryExperiences'] = [];
  @observable profileUpdatedAt: ProfileStoreData['profileUpdatedAt'] = null;
  @observable linkedinUsername: ProfileStoreData['linkedinUsername'];
  @observable githubUsername: ProfileStoreData['githubUsername'];
  @observable defaultRates: ProfileStoreData['defaultRates'];
  @observable submitEditsLater?: ProfileStoreData['submitEditsLater'] = false;
  @observable
  highlightedAdminNoteId?: ProfileStoreData['highlightedAdminNoteId'];

  MIN_PROJECTS = 1;
  MIN_PROJECTS_APPLICATION = 2;
  MAX_FEATURED_SKILLS = 20;

  DEFAULT_PROFILE_IMAGE_PREFIX_PROD = `${CDN_BASE_URL}/avatars/defaults/`;
  DEFAULT_PROFILE_IMAGE_PREFIX_SANDBOX =
    'https://platform-lookaside.fbsbx.com/platform/profilepic/';

  private authStore: Stores['auth'];
  private missionsStore?: Stores['missions'];
  private analytics: PlatformServiceAnalytics;

  public constructor(
    profileData: UserObject,
    auth: Stores['auth'],
    analytics: PlatformServiceAnalytics,
    missionsStore: Stores['missions'] | undefined,
    isTeamGraphPreview: boolean | null = null,
  ) {
    this.authStore = auth;
    this.missionsStore = missionsStore;
    this.analytics = analytics;
    this.data = profileData;
    this.isTeamGraphPreview = isTeamGraphPreview;
    this.setInitialData();
  }

  serialize = (): SerializedProfileStoreData => {
    return {
      data: this.data,
      mode: this.mode,
      jobs: this.jobs,
      projects: this.projects,
      websites: this.websites,
      skills: this.skills,
      roles: this.roles,
      generalTalentCategory: this.generalTalentCategory,
      showErrors: this.showErrors,
      application: this.application?.serialize(),
      adminNotes: this.adminNotes?.serialize(),
      vetterConfiguration: this.vetterConfiguration?.serialize(),
      isTeamGraphPreview: this.isTeamGraphPreview,
      industryExperiences: this.industryExperiences,
      profileUpdatedAt: this.profileUpdatedAt,
      showConfirmSubmitEdits: this.showConfirmSubmitEdits,
      readonly: this.readonly,
      linkedinUsername: this.linkedinUsername,
      githubUsername: this.githubUsername,
      defaultRates: this.defaultRates,
      submitEditsLater: this.submitEditsLater,
    };
  };

  getEmptyJob(): NewJob {
    return {
      _id: generateUniqueId(),
      type: ExperienceType.Job,
      jobRole: '',
      name: '',
      members: this.authStore.user
        ? [
            {
              ...this.authStore.user,
              ...{
                memberTitle: this.authStore.user.roleCategory?.title || '',
                experienceRole: ExperienceMemberRole.Owner,
                collaboratorStatus: CollaboratorStatus.Active,
              },
            },
          ]
        : [],
    };
  }

  getEmptyProject = (placeholder?: boolean): NewProject => {
    return {
      _id: generateUniqueId(),
      type: ExperienceType.Project,
      title: '',
      jobRoleId: this.mainRole?.id || '',
      jobRole: '',
      description: '',
      members: this.authStore.user
        ? [
            {
              ...this.authStore.user,
              ...{
                memberTitle: this.authStore.user.roleCategory?.title || '',
                experienceRole: ExperienceMemberRole.Owner,
                collaboratorStatus: CollaboratorStatus.Active,
              },
            },
          ]
        : [],
      ...(placeholder && {
        placeholder: true,
      }),
    };
  };

  getEmptyWebsite(): Website {
    return {
      _id: generateUniqueId(),
      url: '',
    };
  }

  updateProfileImage = (profilePictureURL: string): Promise<void> => {
    return apiUser
      .updateAppearance(this.authStore, { profilePictureURL })
      .then((user): void => this.setProfileImage(user.profilePictureURL));
  };

  updatePersonalInfo = async (): Promise<void> => {
    await apiUser.updatePersonalInfo(this.authStore, this.personalInfo);
    this.setLocation(this.personalInfo.location);
  };

  updatePortfolio = async (): Promise<void> => {
    if (!this.portfolio) return Promise.resolve();
    await apiUser.updateDesignerPortfolio(this.authStore, this.portfolio);
    this.setPortfolio(this.portfolio);
  };

  updateAvailability = async (isAdmin?: boolean): Promise<void> => {
    if (
      !this.availability ||
      this.availabilityFormattedData.type === AvailableType.UnknownAvailability
    ) {
      throw Error('No availability selected');
    }

    const availabilityData = isAdmin
      ? await apiUsers.adminUpdateAvailability(
          this.authStore,
          this.data.username,
          this.availabilityFormattedData,
        )
      : await apiUser.updateAvailability(
          this.authStore,
          this.availabilityFormattedData,
        );

    if (availabilityData) {
      this.setAvailability(availabilityData);

      // Update the selected reminder period
      if (availabilityData.remindMeAt && availabilityData.updatedAt) {
        this.setSelectedAvailabilityReminderPeriod(
          getReminderPeriod(
            new Date(availabilityData.updatedAt),
            new Date(availabilityData.remindMeAt),
          ),
        );
      }

      this.analytics.trackAvailabilityUpdated(
        availabilityData,
        isAdmin
          ? AvailabilityUpdateSource.AdminNotes
          : AvailabilityUpdateSource.Profile,
        this.availabilityReminderPeriod,
        this.data.uid,
      );
    }
  };

  saveSkills = async (): Promise<void> => {
    await apiUser.updateTalentSkills(this.authStore, {
      mainTalentSkills: this.mainSkills.map(expertiseToSkill),
      additionalTalentSkills: this.additionalSkills.map(expertiseToSkill),
    });
  };

  saveRoles = async (): Promise<void> => {
    await apiUser.updateTalentSpecializations(this.authStore, {
      mainSpecializationId: this.mainRole?.id,
      additionalSpecializationIds: this.roles.map((role) => role.id),
    });
  };

  updateProfileExperience = async (): Promise<void> => {
    if (!this.userExperience) return Promise.resolve();
    await apiUser.updateExperience(this.authStore, this.userExperience);
  };

  getGeneralTalentCategory = async (): Promise<void> => {
    const skills = await apiTalentCategories.queryTalentCategories(
      this.authStore,
      {
        textId: GENERAL_TALENT_CATEGORY_TEXTID,
      },
    );
    this.setGeneralTalentCategory(skills.items[0]);
  };

  querySkills = async (
    query: string,
    useLimit = true,
  ): Promise<Expertise[]> => {
    const skills = await fetchSkillList({
      filter: {
        query,
        nodeType: 'skill',
      },
    });

    const filteredSkills = filterOutBracketedSkills(skills);

    return filteredSkills;
  };

  queryRoles = async (
    query: string,
    includeCategory?: boolean,
    excludeMainRole = false,
  ): Promise<Expertise[]> => {
    const roles = await apiTalentSpecializations.queryTalentSpecializations(
      this.authStore,
      {
        searchTerm: query,
        talentCategoryId:
          includeCategory && this.mainRole?.categoryIds
            ? this.mainRole?.categoryIds[0]
            : undefined,
        exclude: excludeMainRole ? this.mainRole?.id : undefined,
      },
    );

    return roles.items.map((role) => roleToExpertise(role));
  };

  queryIndustries = async (query: string): Promise<Expertise[]> => {
    const items = await fetchIndustryList({
      filter: {
        query,
      },
    });

    return items.map(({ id, name }) => ({
      id,
      name,
      featured: true,
    }));
  };

  didProjectChange = (projectData: ExistingProject): boolean => {
    const originalProject = this.data.projects.find(
      (project) => project.eid === projectData.eid,
    );
    if (!originalProject) return false;

    return (
      originalProject.title !== projectData.title ||
      originalProject.jobRole !== projectData.jobRole ||
      originalProject.description !== projectData.description ||
      originalProject.logoURL !== projectData.logoURL ||
      originalProject.imageURL !== projectData.imageURL ||
      this.didProjectMembersChange(originalProject.members, projectData.members)
    );
  };

  didProjectMembersChange = (
    originalMembers: ExperienceMember[],
    currentMembers: ExperienceMember[],
  ): boolean => {
    if (originalMembers.length !== currentMembers.length) return true;

    return originalMembers.some((member) => {
      return !currentMembers.find(
        (item) => getProjectMemberId(member) === getProjectMemberId(item),
      );
    });
  };

  createProject = async (projectData: NewProject): Promise<void> => {
    if (isEmptyProject(projectData)) return;
    const project = await apiExperiences.createUserExperience(this.authStore, {
      ...projectData,
      source: projectData.isSuggested
        ? ExperienceSource.AISuggestion
        : ExperienceSource.Default,
    });
    if (project) {
      this.localHiddenAndSelectedProjects.hidden.has(
        getProjectId(projectData),
      ) && this.application?.replaceHiddenProject(projectData._id, project.eid);
      this.setProjects([
        ...this.projects.filter(
          (item) => getProjectId(item) !== getProjectId(projectData),
        ),
        project as ExistingProject,
      ]);
      this.setOriginalProjects([
        ...this.data.projects,
        project as ExistingProject,
      ]);
      this.authStore.fetchData(true);
    }
  };

  updateProject = async (projectData: ExistingProject): Promise<void> => {
    // Add experience owner
    const data =
      projectData.members.length > 0 &&
      projectData.members.find(
        (member) => member.experienceRole === ExperienceMemberRole.Owner,
      )
        ? projectData
        : {
            ...projectData,
            members: [
              {
                ...this.authStore.user,
                ...{
                  memberTitle: this.authStore.user?.roleCategory?.title || '',
                  experienceRole: ExperienceMemberRole.Owner,
                  collaboratorStatus: CollaboratorStatus.Active,
                },
              },
              ...projectData.members,
            ],
          };

    const project = await apiExperiences.updateExperience(
      this.authStore,
      projectData.eid,
      data,
    );

    if (project) {
      const projects = [...this.projects];
      const index = this.projects.findIndex(
        (item) => getProjectId(item) === getProjectId(projectData),
      );
      projects[index] = project as ExistingProject;
      this.setProjects(projects);
    }
  };

  deleteProject = async (eid: ExperienceId): Promise<void> => {
    const localProjects = [...this.projects];
    const originalProjects = [...this.data.projects];

    await apiExperiences.deleteExperience(this.authStore, eid);

    const originalProjectsUpdated = originalProjects.filter(
      (project) => getProjectId(project) !== eid,
    );
    const localProjectsUpdated = localProjects.filter(
      (project) => getProjectId(project) !== eid,
    );

    this.setProjects(localProjectsUpdated);
    this.setOriginalProjects(originalProjectsUpdated);
    this.application?.setHiddenProject(eid, true);
  };

  saveProjects = async (): Promise<void> => {
    const actions: Promise<void>[] = [];
    // Prepare projects for deletion
    this.localDeletedProjects.forEach(
      (project) =>
        !isNewExperienceItem(project) &&
        actions.push(this.deleteProject(project.eid)),
    );

    this.projects.forEach((project) => {
      actions.push(
        isNewExperienceItem(project)
          ? this.createProject(project)
          : this.updateProject(project),
      );
    });

    await Promise.all(actions);
  };

  createJob = async (jobData: NewJob): Promise<void> => {
    const job = await apiExperiences.createUserExperience(
      this.authStore,
      jobData,
    );
    if (job) {
      const filteredJobs = this.jobs.filter(
        (existingJob) => getJobId(existingJob) !== getJobId(jobData),
      );
      this.setJobs([
        ...filteredJobs,
        {
          ...job,
          ...{
            jobRole: jobData.jobRole,
            name: jobData.name,
            type: jobData.type,
          },
        },
      ]);
    }
  };

  updateJob = async (jobData: ExistingJob): Promise<void> => {
    if (!this.didJobChange(jobData)) return Promise.resolve();

    const job = await apiExperiences.updateExperience(
      this.authStore,
      jobData.eid,
      jobData,
    );

    if (job) {
      const jobs = [...this.jobs];
      const index = jobs.findIndex((item) => job.eid === getJobId(item));
      jobs[index] = { ...job, ...jobData };
      this.setJobs(jobs);
    }
  };

  didJobChange = (jobData: ExistingJob): boolean => {
    const originalJob = this.data.jobs.find((job) => job.eid === jobData.eid);
    if (!originalJob) return false;

    return !isEqual(originalJob, jobData);
  };

  deleteJob = async (eid: ExperienceId): Promise<void> => {
    await apiExperiences.deleteExperience(this.authStore, eid);
    this.setJobs(this.jobs.filter((job) => getJobId(job) !== eid));
  };

  filterOutDeletedJob = (eid: ExperienceId): void => {
    this.setJobs(this.jobs.filter((job) => getJobId(job) !== eid));
  };

  saveJobs = async (): Promise<void> => {
    const actions: Promise<void>[] = [];
    this.setJobs(this.jobs.filter((job) => !!job.name));

    // Check for deleted jobs
    this.data.jobs.forEach((job) => {
      const jobDeleted = !this.jobs.find(
        (item) => getJobId(item) === getJobId(job),
      );
      jobDeleted && actions.push(this.deleteJob(job.eid));
    });

    this.jobs.forEach((job) => {
      actions.push(
        isNewExperienceItem(job) ? this.createJob(job) : this.updateJob(job),
      );
    });

    await Promise.all(actions);
  };

  saveIndustryExperiences = async (): Promise<void> => {
    const experiences = this.industryExperiences.map(({ id, name }) => ({
      talentIndustryId: id,
      jobTitles: [],
    }));
    await apiUser.updateTalentIndustries(this.authStore, {
      experiences,
    });
  };

  saveDefaultRates = async (): Promise<void> => {
    if (this.defaultRates) {
      await apiUser.updateDefaultRates(this.authStore, this.defaultRates);
    }
  };

  getProfileSavingSections = (
    withApplication?: boolean,
  ): { [key: string]: ProfileSectionSavingInfo } => ({
    projects: {
      condition: this.didProjectsChange,
      action: this.saveProjects,
    },
    jobs: {
      condition: this.didJobsChange,
      action: this.saveJobs,
    },
    personalInfo: {
      condition: this.didPersonalInfoChange,
      action: this.updatePersonalInfo,
    },
    portfolio: {
      condition: this.didPortfolioChange,
      action: this.updatePortfolio,
    },
    availability: {
      condition: !!(!withApplication && this.didAvailabilityChange),
      action: this.updateAvailability,
    },
    skills: {
      condition: !!this.didSkillsChange,
      action: this.saveSkills,
    },
    roles: {
      condition: !!this.didRolesChange,
      action: this.saveRoles,
    },
    industryExperiences: {
      condition: this.didIndustryExperiencesChange,
      action: this.saveIndustryExperiences,
    },
    profileExperience: {
      condition: true,
      action: this.updateProfileExperience,
    },
    defaultRates: {
      condition: this.didDefaultRatesChange,
      action: this.saveDefaultRates,
    },
  });

  getProfileSectionsToSave = (
    sections: Array<ProfileSectionSavingInfo>,
  ): Array<ProfileSectionSavingInfo> =>
    sections.filter((section) => !!section.condition);

  saveProfile = async (withApplication?: boolean): Promise<void> => {
    if (!this.isMinimumProfileFieldsValid) {
      this.setShowErrors(true);
      return Promise.reject(null);
    }

    if (
      !this.authStore.withOnboardingV2 &&
      this.scrubbedMinusOneReasons.length > 0 &&
      !this.showConfirmSubmitEdits
    ) {
      this.setShowConfirmSubmitEdits(true);
      return Promise.reject(null);
    }
    this.setShowConfirmSubmitEdits(false);
    this.setProfileUpdatedAt(new Date());
    //get all profile sections
    const allProfileSections = Object.values(
      this.getProfileSavingSections(withApplication),
    );

    //filter sections to save
    const saveProfileMethods =
      this.getProfileSectionsToSave(allProfileSections);

    //save filtered sections
    await Promise.all(
      saveProfileMethods.map((section) => section.action.call(this)),
    );

    if (
      !this.authStore.withOnboardingV2 &&
      this.scrubbedMinusOneReasons.length > 0 &&
      !this.submitEditsLater
    ) {
      await apiUser.confirmUpdatesSubmission(this.authStore);
      await this.authStore.renewToken();
    }

    //update user data
    const user = await apiUser.getCurrentUser(this.authStore);

    if (user) {
      this.setData(user as CurrentUserObject);
      this.authStore.setUser(user);
    }

    this.setShowErrors(false);

    if (!withApplication) {
      this.setProfileMode(ProfileViewMode.View);
      this.analytics.trackProfileSaveButtonClicked();
    }
  };

  saveApplication = async (): Promise<void> => {
    if (!this.allFieldsValid) {
      this.setShowErrors(true);
      return Promise.reject(null);
    }
    this.application?.setRequirementsModalOpen(false);

    // Only update if updateAvailabilityOnSubmit is true
    if (this.application?.updateAvailabilityOnSubmit) {
      await this.authStore.updateAvailabilityHours(
        this.application?.availability.hoursPerWeek || this.availabilityHours,
        undefined,
      );
    }

    if (this.application?.updateWorkingHoursOnSubmit) {
      this.setWorkingHours(this.application.workingHours);
    }

    // for correct hiddenProjects ids
    const skipRecommendations =
      this.application?.shouldSkipApplicationRecommendations;
    await this.saveProfile(true);

    const application = await this.submitApplication();
    const calendar = await apiUser.getUserCalendar(this.authStore);

    if (!application) return Promise.reject();

    if (skipRecommendations && application) {
      this.application?.mid &&
        this.setApplication(
          {
            aid: application.aid,
            rid: application.rid,
            mid: this.application?.mid,
          },
          application,
        );
      this.setProfileMode(ProfileViewMode.View);
      this.application?.setMode(ApplicationViewMode.View);
      this.application?.setSubmitted(true);
      this.missionsStore?.currentMission?.updateRoleApplicationWithdrawn(
        application.rid,
        false,
      );
      return;
    }

    this.application?.setApplicationData(application);

    if (this.authStore.withSaveApplicationProgress) {
      // Clear the application data from local storage
      this.application?.clearApplicationDataFromLocalStorage();
    }

    const onboardingCompleted = this.authStore.onboardingCompleted;

    if (!onboardingCompleted) {
      this.application?.setSubmitted(true);
    }

    if (
      this.authStore.withSharedCalendarFlow &&
      !calendar.calcom?.selectedCalendar &&
      calendar.calcom?.hasAvailability
    ) {
      this.application?.gotoInterviewAvailability();
    } else {
      if (onboardingCompleted) {
        this.application?.gotoRecommendations();
      }
    }
  };

  submitApplication = async (): Promise<MissionApplicationObject> => {
    if (!this.application?.rid || !this.application?.mid)
      return Promise.reject();
    let res;
    if (this.application.aid) {
      res = await apiMissions.updateMissionApplication(
        this.authStore,
        this.application.mid,
        this.application.aid,
        { ...this.application.applicationData, withdrawn: false },
      );
    } else {
      if (!this.missionsStore) return Promise.reject();
      res = await this.missionsStore?.applyMission(
        this.application.rid,
        this.application.applicationData,
        this.application.mid,
      );
      this.application?.trackApplicationSubmit();
    }
    return res;
  };

  updateAdminNotes = async (data: AdminNotesRequestPayload): Promise<void> => {
    const notes = await apiUsers.adminAddNotes(
      this.authStore,
      this.data.username,
      data,
    );

    this.setAdminNotes(notes);
  };

  updateScrubbed = async (scrubbed: UserScrubbed): Promise<void> => {
    await apiUsers.updateUserScrubbed(this.authStore, this.data.username, {
      scrubbed,
      updateUserStatus: false,
    });
    this.setUserScrubbed(scrubbed);
  };

  markUserAsRejected = async (): Promise<void> => {
    await apiUsers.adminMarkAsRejected(this.authStore, this.data.username);
    await this.fetchAdminNotes();
    this.setMarkedAsRejected(true);
  };

  unmarkUserAsRejected = async (): Promise<void> => {
    await apiUsers.adminUnmarkAsRejected(this.authStore, this.data.username);
    await this.fetchAdminNotes();
    this.setMarkedAsRejected(false);
  };

  cancelAutomatedRejection = async (): Promise<void> => {
    await apiUsers.adminCancelAutomatedRejection(
      this.authStore,
      this.data.username,
    );
    this.setData({
      ...this.data,
      pendingAutomatedRejection: false,
    });
  };

  updateUserCustomTags = async (tagIds: string[]): Promise<void> => {
    const userCustomTags = await apiUsers.updateUserCustomTags(
      this.authStore,
      this.data.username,
      { customTags: tagIds },
    );
    this.setUserCustomTags(userCustomTags);
  };

  removeAdminNote = async (nid: AdminNoteId): Promise<void> => {
    if (!this.adminNotes) {
      return;
    }

    await apiUsers.adminDeleteNote(this.authStore, this.data.username, nid);
    this.setAdminNotes({
      ...this.adminNotes.data,
      notes: this.adminNotes.data.notes.filter((note) => note.nid !== nid),
    });
  };

  onProfileEditClick = (): Promise<void> => {
    this.setProfileMode(ProfileViewMode.Edit);
    this.analytics.trackProfileEditButtonClicked();
    return Promise.resolve();
  };

  onAdminNotesSaveClick = async (): Promise<void> => {
    const promises = [];

    if (
      this.data.availability &&
      this.availabilityFormattedData.type !==
        AvailableType.UnknownAvailability &&
      this.didAvailabilityChange
    ) {
      promises.push(this.updateAvailability(true));
    }

    if (
      this.vetterConfiguration &&
      this.vetterConfiguration.hasVetterConfigurationChanged
    ) {
      promises.push(this.updateVetterConfiguration());
    }

    this.setProfileMode(ProfileViewMode.View);
    this.setAdminNotes(null);

    await Promise.all(promises);
  };

  removeDeletedProjectMembers = (projects: LocalProjects): LocalProjects => {
    return projects.map((project) => {
      const updatedProject = { ...project };
      updatedProject.members = updatedProject.members.filter(
        (member) =>
          isProjectMemberUser(member) &&
          (!member.deleted || member.uid === this.data.uid),
      );
      return updatedProject;
    });
  };

  startVettingProcess = async (
    payload: Omit<StartVettingProcessPayload, 'userId'>,
  ): Promise<void> => {
    await apiVettingProcess.startVettingProcess(this.authStore, {
      ...payload,
      userId: this.data.uid,
    });

    this.setVettingScheduled(true);
  };

  sendEvaluationInviteFollowup = async (
    payload: SendEvaluationInvitationPayload,
  ): Promise<void> => {
    await apiVettingProcess.sendEvaluationInvite(this.authStore, payload);
  };

  updateInviterUser = async (user: AdminBasicUserObject): Promise<void> => {
    if (!this.authStore.uid) return;

    await apiReferrals.adminUpdateInviterUser(this.authStore, {
      userId: this.data.uid,
      inviterUserId: user.uid,
      refreshInviterRewardsScores: true,
    });
    this.setInviterUser(user);
  };

  @computed get minProjects(): number {
    if (this.application) {
      return this.MIN_PROJECTS_APPLICATION;
    }

    if (!this.authStore.withOnboardingV2) {
      return this.MIN_PROJECTS;
    }

    return 0;
  }

  @computed get isDesigner(): boolean {
    return (
      this.data.talentProfile?.talentSpecializations?.mainTalentSpecialization?.talentCategoryIds.includes(
        DESIGNER_CATEGORY_ID,
      ) ?? false
    );
  }

  @computed get isSoftwareEngineer(): boolean {
    return (
      this.data.talentProfile?.talentSpecializations?.mainTalentSpecialization?.talentCategoryIds.includes(
        SOFTWARE_ENGINEER_CATEGORY_ID,
      ) ?? false
    );
  }

  @computed get totalWorkConnections(): number {
    return this.data?.connectionsSummary?.work.totalConnections || 0;
  }

  @computed get totalPendingConnections(): number {
    return this.data?.connectionsSummary?.totalPendingConnections || 0;
  }

  @computed get totalTeamUpConnections(): number {
    return this.data?.connectionsSummary?.teamUp.totalConnections || 0;
  }

  @computed get totalOverallConnections(): number {
    return this.data?.connectionsSummary?.totalConnections || 0;
  }

  @computed get hasConnection(): boolean {
    return this.data.connections.length > 0;
  }

  @computed get connections(): BasicConnectionObjectV2[] {
    return this.data.connections;
  }

  @computed get readonly(): boolean {
    return this.mode === ProfileViewMode.View;
  }

  @computed get isCurrentUser(): boolean {
    return this.authStore.user?.uid === this.data.uid;
  }

  @computed get historicRate(): number {
    if (!this.isCurrentUser) {
      return 0;
    }

    if (this.data?.historicAverageHourlyRate) {
      return this.data.historicAverageHourlyRate;
    }

    return this.data?.rateRange?.min ?? 0;
  }

  @computed get minimumHourlyRate(): number {
    if (!this.isCurrentUser) {
      return 0;
    }

    return this.defaultRates?.hourlyRate ?? 0;
  }

  @computed get minimumMonthlyRate(): number {
    if (!this.isCurrentUser) {
      return 0;
    }

    return this.defaultRates?.monthlyRate ?? 0;
  }

  @computed get showUnderReviewBanner(): boolean {
    return this.isCurrentUser && !this.authStore.currentUser?.wasScrubbed;
  }

  @computed get displayedProjects(): LocalProjects {
    const filteredProjects = this.projects.filter((project) => {
      const currentUserDeleted = project.members.find(
        (member) =>
          isProjectMemberUser(member) &&
          member.uid === this.data.uid &&
          member.deleted,
      );
      // In an application, projects that are both deleted and hidden should not show
      // if they are deleted but not hidden - we still want to show them
      if (currentUserDeleted && this.application) {
        return !this.application.data.hiddenProjects?.includes(
          getProjectId(project),
        );
      }
      return !currentUserDeleted;
    });

    let returnedProjects = this.removeDeletedProjectMembers(filteredProjects);

    if (
      this.authStore.withApplicationBuilderScoring &&
      this.application?.data.recommendedProjects
    ) {
      returnedProjects = orderBy(
        returnedProjects,
        (i) => {
          const match = this.application?.data.recommendedProjects?.find(
            (recommendedProject) => recommendedProject.eid === getProjectId(i),
          );
          if (!match) return 0;
          return (
            match.matches.industry.count +
            match.matches.role.count +
            match.matches.skills.count
          );
        },

        ['desc'],
      );
    }

    return returnedProjects;
  }

  @computed get displayedProjectIds(): string[] {
    return this.displayedProjects.map((project) => getProjectId(project));
  }

  @computed get savedProjectIds(): string[] {
    return this.displayedProjects
      .filter((project) => !isNewExperienceItem(project))
      .map((project) => getProjectId(project));
  }

  @computed get scrubbedMinusOneReasons(): ScrubReason[] {
    return this.data.scrubbedMinusOneReasons || [];
  }

  /**
   * Returns the selected and hidden project ids that exist in the current project list
   * Excluding projects that were deleted completely
   */
  @computed get localHiddenAndSelectedProjects(): {
    hidden: Map<ExperienceId, boolean>;
    selected: ExperienceId[];
  } {
    // if not in application mode, bail out early
    if (!this.application) {
      return {
        selected: [],
        hidden: new Map(),
      };
    }

    // we filter out all hidden projects that were deleted
    const hiddenProjects =
      this.application?.applicationData.hiddenProjects?.filter((id) =>
        this.displayedProjectIds.includes(id),
      ) ?? [];

    // this block would only be executed if a user is editing an esisting application or already have some selections
    return {
      hidden: new Map(hiddenProjects?.map((id) => [id, true]) || []),
      selected: this.displayedProjectIds.filter(
        (id) => !this.application?.applicationData.hiddenProjects?.includes(id),
      ),
    };
  }

  /**
   * Returns all locally deleted projects
   */
  @computed get localDeletedProjects(): LocalProjects {
    const originalNonDeletedProjects = this.data.projects.filter(
      (project) => !this.deletedProjectIds.includes(getProjectId(project)),
    );
    return originalNonDeletedProjects.filter((project) => {
      return !this.displayedProjects.find(
        (item) => getProjectId(item) === getProjectId(project),
      );
    });
  }

  /**
   * Returns ids of originally deleted projects
   */
  @computed get deletedProjectIds(): ExperienceId[] {
    return this.data.projects
      .filter((project) => {
        return project.members.find(
          (member) =>
            isProjectMemberUser(member) &&
            member.uid === this.data.uid &&
            member.deleted,
        );
      })
      .map((project) => getProjectId(project));
  }

  @computed get addedProjects(): NewProject[] {
    return this.displayedProjects.filter(
      (project) =>
        isNewExperienceItem(project) && !isPlaceholderProject(project),
    ) as NewProject[];
  }

  @computed get roleTags(): string[] {
    return this.roles.map((role) => role.name);
  }

  @computed get allRoles(): Expertise[] {
    if (!this.mainRole) return this.roles;
    return [this.mainRole, ...this.roles];
  }

  @computed get canScrub(): boolean {
    const isFullyVetted = [
      this.data.expertiseScore,
      this.data.interactionExperienceScore,
      this.data.englishScore,
      this.data.accentScore,
    ].every(Number.isInteger);

    return !isFullyVetted;
  }

  @computed get vettingScores(): AdminNotesObject['scores'] {
    return {
      expertise: this.data.expertiseScore,
      interactionExperience: this.data.interactionExperienceScore,
      english: this.data.englishScore,
      accent: this.data.accentScore,
    };
  }

  @computed get industryExperienceTags(): string[] {
    return this.industryExperiences.map(({ name }) => name);
  }

  @computed get mainSkills(): Expertise[] {
    return this.skills.main;
  }

  @computed get additionalSkills(): Expertise[] {
    return this.skills.additional;
  }

  @computed get allSkills(): Expertise[] {
    const allSkills = sortBy(
      [...this.mainSkills, ...this.additionalSkills],
      ['featured: desc', 'rating: desc'],
    ).map((s) => ({
      ...s,
      verifiedSkill:
        this.data.verifiedSkills?.find((vs) => vs === s.id) !== undefined,
    }));
    return allSkills;
  }

  @computed get displayedSkills(): Expertise[] {
    if (this.authStore.isAdmin || (this.isCurrentUser && !this.readonly)) {
      return this.allSkills;
    }
    return this.mainSkills;
  }

  @computed get allTalentCategoryIds(): TalentCategoryId[] {
    const ids: TalentCategoryId[] = [];
    const roles: Expertise[] = this.mainRole
      ? [...this.roles, this.mainRole]
      : [...this.roles];
    roles.forEach((role) => role.categoryIds && ids.push(...role.categoryIds));
    return ids;
  }

  @computed get city(): string {
    return this.location?.city || '';
  }

  @computed get province(): string {
    return this.location?.province || '';
  }

  @computed get country(): string {
    return this.location?.country || '';
  }

  @computed get countryRawObject(): RawCountry | undefined {
    return rawCountryList.find(
      (country) => country.code === this.location?.countryShortName,
    );
  }

  @computed get availabilityData(): AvailabilitySummaryObject {
    if (this.application) {
      return {
        type: AvailableType.Now,
        availableFrom: new Date(
          this.application?.availability.startDate,
        ).toISOString(),
        weeklyHoursAvailable: this.application.availability.hoursPerWeek,
        notes: this.availabilityNotes,
      };
    }

    return {
      type: this.availabilityType || AvailableType.UnknownAvailability,
      availableFrom: this.availabilityFromDate
        ? this.availabilityFromDate.toISOString()
        : undefined,
      weeklyHoursAvailable: this.availabilityHours,
      keepNotifications: this.keepNotifications,
      notes: this.availabilityNotes,
    };
  }

  @computed get profileAvailabilityData(): AvailabilitySummaryObject {
    const roleId = this.application?.rid;
    const profileAvailHours = this.availability?.weeklyHoursAvailable;
    const profileAvailDate = this.availability?.availableFrom;
    const profileAvailType = this.availability?.type;
    const deafultAvail = {
      type: this.availability?.type || AvailableType.UnknownAvailability,
      availableFrom:
        this.availability?.type === AvailableType.FutureDate
          ? profileAvailDate
          : undefined,
      weeklyHoursAvailable: profileAvailHours,
      updatedAt: this.availability?.updatedAt,
    };
    if (roleId) {
      const currentRole = this.missionsStore?.currentMissionRoleById(roleId);
      const roleHours = currentRole?.availability?.weeklyHoursAvailable;
      const roleDate = currentRole?.availability?.date?.toString();

      if (roleHours && profileAvailHours) {
        if (roleHours >= profileAvailHours) {
          deafultAvail.weeklyHoursAvailable = roleHours;
        } else {
          if (
            profileAvailType === AvailableType.Now ||
            profileAvailType === AvailableType.FutureDate
          ) {
            deafultAvail.weeklyHoursAvailable = profileAvailHours;
          }
        }
      }
      if (
        profileAvailDate &&
        roleDate &&
        isBefore(parseISO(roleDate), parseISO(profileAvailDate))
      ) {
        deafultAvail.availableFrom = roleDate;
      }
      // Check to make sure all dates are before today
      if (
        deafultAvail.availableFrom &&
        isBefore(parseISO(deafultAvail.availableFrom), Date.now())
      ) {
        deafultAvail.availableFrom = formatISO(Date.now());
      }
    }
    return deafultAvail;
  }

  @computed get availabilityType(): AvailableType {
    return this.availability?.type || AvailableType.UnknownAvailability;
  }

  @computed get availabilityHours(): number | undefined {
    if (this.isCurrentUser || this.authStore.isAdmin) {
      return this.availability?.weeklyHoursAvailable;
    }
    return undefined;
  }

  @computed get keepNotifications(): boolean | undefined {
    if (this.isCurrentUser || this.authStore.isAdmin) {
      return this.availability?.keepNotifications;
    }
    return undefined;
  }

  @computed get availabilityNotes(): string | undefined {
    if (this.isCurrentUser || this.authStore.isAdmin) {
      return this.availability?.notes;
    }
    return undefined;
  }

  @computed get lastAvailabilityUpdate(): Date | undefined {
    return this.availability?.updatedAt
      ? new Date(this.availability.updatedAt)
      : undefined;
  }

  @computed get availabilityFromDate(): Date | undefined {
    return this.availability?.availableFrom
      ? new Date(this.availability.availableFrom)
      : undefined;
  }

  @computed get availabilityRemindMeAt(): Date | undefined {
    return this.availability?.remindMeAt
      ? new Date(this.availability.remindMeAt)
      : undefined;
  }

  @computed get availabilityFormattedData(): AvailabilityData {
    switch (this.availabilityType) {
      case AvailableType.Now:
        return {
          type: AvailableType.Now,
          weeklyHoursAvailable: this.availabilityHours || 0,
          notes: this.availabilityNotes,
        };
      case AvailableType.FutureDate:
        return {
          type: AvailableType.FutureDate,
          date: this.availabilityFromDate
            ? this.availabilityFromDate.toISOString()
            : add(new Date(), { days: 1 }).toISOString(),
          weeklyHoursAvailable: this.availabilityHours || 0,
          notes: this.availabilityNotes,
        };
      case AvailableType.NotAvailable:
        return {
          type: AvailableType.NotAvailable,
          remindMeAt: this.availabilityRemindMeAt
            ? this.availabilityRemindMeAt.toISOString()
            : add(new Date(), {
                months: 1,
              }).toISOString(),
          keepNotifications: this.keepNotifications,
          notes: this.availabilityNotes,
        };
      default:
        return {
          type: AvailableType.UnknownAvailability,
        };
    }
  }

  @computed get showAvailabilityBanner(): boolean {
    return (
      !this.application &&
      !!this.availability &&
      this.readonly &&
      this.availabilityType !== AvailableType.UnknownAvailability
    );
  }

  /***
   * Returns true if we are in application view or profile edit mode
   * Only for current user or admins
   */
  @computed get showAvailabilitySection(): boolean {
    return (
      (this.isCurrentUser || this.authStore.isAdmin) &&
      (!!this.application || !this.readonly)
    );
  }

  @computed get showSkillsSection(): boolean {
    return (
      this.isCurrentUser &&
      !!this.application &&
      !this.readonly &&
      (!!this.application.currentRole?.requiredSkills?.length ||
        !!this.application.currentRole?.preferredSkills?.length)
    );
  }

  @computed get showLocationSection(): boolean {
    return (
      this.isCurrentUser &&
      !!this.application &&
      !this.application.userLocationInRoleReqs
    );
  }

  @computed get showYears(): boolean {
    return !!this.yearsOfExperience || !this.readonly;
  }

  @computed get showRoles(): boolean {
    return !!this.roleTags.length || !this.readonly;
  }

  @computed get showIndustries(): boolean {
    return !!this.industryExperiences.length || !this.readonly;
  }

  @computed get showWebsites(): boolean {
    return !!this.websites?.length || !this.readonly;
  }

  @computed get showJobs(): boolean {
    return (
      !this.authStore.withNewJobs && (!!this.jobs?.length || !this.readonly)
    );
  }

  @computed get showSkills(): boolean {
    if (this.authStore.isAdmin) return true;

    return !!this.mainSkills.length || !this.readonly;
  }

  @computed get showLocation(): boolean {
    if (this.application && !this.application.readonly) return false;
    return !!this.city || !!this.country || !this.readonly;
  }

  @computed get showTimezone(): boolean {
    return this.authStore.isAdmin;
  }

  @computed get showWorkingHours(): boolean {
    return (
      (this.isCurrentUser || this.authStore.isAdmin) &&
      (!!this.workingHours || !this.readonly)
    );
  }

  @computed get showPhone(): boolean {
    return (
      (!this.readonly && this.isCurrentUser) ||
      (this.readonly && this.isCurrentUser && !!this.phone?.length) ||
      (this.authStore.isAdmin && !!this.phone)
    );
  }

  @computed get showPortfolio(): boolean {
    const hasDetails = this.portfolio?.description && this.portfolio.url;
    return !!(
      (this.isDesigner && !this.readonly) ||
      (hasDetails && (this.isCurrentUser || this.authStore.isAdmin))
    );
  }

  @computed get showCollaborators(): boolean {
    return this.data.frequentCollaborators.length > 0;
  }

  @computed get showAboutMe(): boolean {
    return (
      !this.application ||
      !this.data.aboutMe?.length ||
      (this.application.readonly && (!this.readonly || !!this.aboutMe))
    );
  }

  @computed get showProjects(): boolean {
    return true;
  }

  @computed get showHourlyRate(): boolean {
    return !!this.application && (this.authStore.isAdmin || this.isCurrentUser);
  }

  @computed get showProfileRates(): boolean {
    if (!this.isCurrentUser) {
      return false;
    }

    if (this.application) {
      return false;
    }

    return !this.readonly;
  }

  @computed get showProfileRatesViewMode(): boolean {
    if (!this.isCurrentUser) {
      return false;
    }

    if (this.application) {
      return false;
    }

    return this.readonly;
  }

  @computed get personalInfo(): PersonalInfoData {
    return {
      websites: this.websites.map((website) => website.url),
      aboutMe: this.aboutMe,
      location: this.location,
      phoneNumber: this.phone,
      timezone: this.timezone,
      workingHours: this.workingHours,
      linkedinUsername: this.linkedinUsername,
      githubUsername: this.githubUsername,
    };
  }

  @computed get userExperience(): UserExperienceData | null {
    return {
      yearsOfExperience: this.yearsOfExperience,
      industries: [],
      expertise: [],
    };
  }

  @computed get ctaOptions(): {
    [k: string]: CTA;
  } {
    return {
      adminNotesSave: {
        label: 'Save',
        callback: () => this.onAdminNotesSaveClick(),
        confirmation: 'Your notes were successfully saved',
      },
      profileEdit: {
        label: 'Edit',
        callback: this.onProfileEditClick,
      },
      profileSave: {
        label: 'Save',
        callback: () => this.saveProfile(),
        confirmation: 'Your profile was successfully updated',
        tooltipErrors: this.profileCTAErrors,
      },
      applicationSave: {
        label: 'Submit',
        callback: () => {
          return this.application
            ? this.checkApplicationRequirements()
            : Promise.reject();
        },
        confirmation: this.application?.shouldShowRequirementsMissingWarning
          ? undefined
          : 'Your application was successfully submitted',
        tooltipErrors: this.applicationCTAErrors,
      },
      recommendationsSubmit: {
        label: 'Send requests',
        disabled: this.application?.requestedUsers.length === 0,
        callback: () => {
          return this.application
            ? this.application.submitRecommendations()
            : Promise.reject();
        },
      },
    };
  }

  @computed get cta(): CTA | undefined {
    if (this.mode === ProfileViewMode.Admin) {
      return this.ctaOptions.adminNotesSave;
    } else if (this.application) {
      return this.application.isRecommendationsStep
        ? this.ctaOptions.recommendationsSubmit
        : this.ctaOptions.applicationSave;
    } else {
      if (this.isCurrentUser) {
        return this.mode === ProfileViewMode.Edit
          ? this.ctaOptions.profileSave
          : this.ctaOptions.profileEdit;
      }
      return undefined;
    }
  }

  @computed get hasValidLinkedIn(): boolean {
    let hasValidLinkedIn = true;
    const linkedInUrl = `https://linkedin.com/in/${this.linkedinUsername}`;
    const isValidUrl = isValidLinkedInUrl(linkedInUrl);

    // if a user never had a linkedin Username (during onboarding they selected I don't have linkedin), a valid state could be either `undefined` or a valid URL
    if (isUndefined(this.data.linkedIn?.username)) {
      hasValidLinkedIn = isUndefined(this.linkedinUsername) || isValidUrl;
    } else {
      // if a user already had a linkedin, they can't submit unless their linkedin url is valid
      hasValidLinkedIn = isValidUrl;
    }

    return hasValidLinkedIn;
  }

  @computed get hasValidGithub(): boolean {
    let hasValidGithub = true;
    const githubUrl = `https://github.com/${this.githubUsername}`;
    const isValidUrl = isValidGithubUrl(githubUrl);

    // if a user never had a GitHub Username, a valid state could be either `undefined` or a valid URL
    if (isUndefined(this.data.github?.username)) {
      hasValidGithub = isUndefined(this.githubUsername) || isValidUrl;
    } else {
      // if a user already had a GitHub, they can't submit unless their GitHub url is valid
      hasValidGithub = isValidUrl;
    }

    return hasValidGithub;
  }

  @computed get profileCTAErrors(): string[] {
    const errors = [];
    this.hasProjectsError && errors.push('Projects');
    this.hasAvailabilityError && errors.push('Availability');
    this.hasAboutMeMaxLengthError &&
      errors.push('Maximum 1000 characters for About text');

    if (!this.hasValidLinkedIn) {
      errors.push('LinkedIn Url');
    }

    if (!this.hasValidGithub) {
      errors.push('GitHub Url');
    }

    return errors;
  }

  @computed get applicationCTAErrors(): string[] {
    const fields: { [k: string]: { errorShown: boolean; text: string } } = {
      about: {
        errorShown: this.hasAboutMeError,
        text: 'About (required and max 1000 characters)',
      },
      roles: {
        errorShown: this.hasRolesError,
        text: 'Main role',
      },
      yearsOfExperience: {
        errorShown: this.hasYearsExperienceError,
        text: 'Years of Experience',
      },
      projects: {
        errorShown: this.hasProjectsError,
        text: 'Minimum two completed projects',
      },
      availability: {
        errorShown: !!this.application?.hasAvailabilityError,
        text: 'Availability',
      },
      teamUpRequestResponses: {
        errorShown: !!this.application?.hasTeamUpRequestResponsesError,
        text: 'You must respond to the pending invites before submitting the application.',
      },
      pitch: {
        errorShown: !!this.application?.hasPitchError,
        text: 'Pitch',
      },
      hourlyRate: {
        errorShown: !!this.application?.hasHourlyRateError,
        text: 'Hourly Rate',
      },
      profilePicture: {
        errorShown: this.hasProfilePictureError,
        text: 'Profile Picture',
      },
      monthlyRate: {
        errorShown: !!this.application?.hasMonthlyRateError,
        text: 'Monthly rate',
      },
    };

    return Object.keys(fields)
      .filter((field) => fields[field].errorShown)
      .map((field) => fields[field].text);
  }

  @computed get hasProfilePictureError(): boolean {
    const isProdDefaultUrl = this.data.profilePictureURL.startsWith(
      this.DEFAULT_PROFILE_IMAGE_PREFIX_PROD,
    );
    const isSandboxDefaultUrl = this.data.profilePictureURL.startsWith(
      this.DEFAULT_PROFILE_IMAGE_PREFIX_SANDBOX,
    );
    return !!this.application && (isProdDefaultUrl || isSandboxDefaultUrl);
  }

  @computed get hasAvailabilityError(): boolean {
    if (this.application) return false;
    return this.availabilityType === AvailableType.UnknownAvailability;
  }

  @computed get hasAvailabilityHoursError(): boolean {
    if (this.application) return false;
    return (
      (this.availabilityType === AvailableType.Now ||
        this.availabilityType === AvailableType.FutureDate) &&
      !this.availabilityHours
    );
  }

  @computed get hasYearsExperienceError(): boolean {
    return (
      !this.yearsOfExperience ||
      this.yearsOfExperience > 99 ||
      this.yearsOfExperience <= 0
    );
  }

  @computed get hasRolesError(): boolean {
    return !!this.application && !this.mainRole;
  }

  @computed get hasAboutMeError(): boolean {
    return !!this.application && (!this.aboutMe || !this.aboutMe.length);
  }

  @computed get hasAboutMeMaxLengthError(): boolean {
    return !!this.aboutMe && this.aboutMe.length > 1000;
  }

  @computed get hasProjectsError(): boolean {
    return this.tooLittleProjects;
  }

  @computed
  get filteredHiddenProjects(): ApplicationStoreData['hiddenProjects'] {
    if (!this.application?.hiddenProjects) return new Map();
    const hidden = new Map(this.application.hiddenProjects);
    this.application.hiddenProjects.forEach((value, key) => {
      const exists = this.projects.find(
        (project) => getProjectId(project) === key,
      );
      !exists && hidden.delete(key);
    });
    return hidden;
  }

  @computed get tooLittleProjects(): boolean {
    if (this.projects.length < this.minProjects) return true;
    if (!this.application || !this.filteredHiddenProjects) return false;
    return (
      this.projects.length - this.filteredHiddenProjects.size < this.minProjects
    );
  }

  @computed get requiredProfileFieldsValid(): boolean {
    return !this.hasProjectsError;
  }

  @computed get isMinimumProfileFieldsValid(): boolean {
    const isApplication = !!this.application;
    const skipAboutMeMaxLength = isApplication;

    return !this.hasProjectsError &&
      !this.hasAvailabilityError &&
      !this.hasAvailabilityHoursError &&
      skipAboutMeMaxLength
      ? true
      : !this.hasAboutMeMaxLengthError && this.hasValidLinkedIn;
  }

  @computed get allFieldsValid(): boolean {
    return (
      !this.hasYearsExperienceError &&
      !this.hasAboutMeError &&
      !this.hasRolesError &&
      !this.hasProjectsError &&
      (this.application ? this.application.isApplicationValid : true) &&
      (this.application ? !this.hasProfilePictureError : true) &&
      (this.application ? !this.application.hasMonthlyRateError : true)
    );
  }

  @computed get isDirty(): boolean {
    return (
      this.didYearsExperienceChange ||
      this.didLocationChange ||
      this.didPhoneChange ||
      this.didWebsitesChange ||
      this.didJobsChange ||
      this.didAboutMeChange ||
      this.didSkillsChange ||
      this.didRolesChange ||
      this.didProjectsChange ||
      this.didAvailabilityChange ||
      this.didTimezoneChange ||
      this.didWorkingHoursChange ||
      this.didIndustryExperiencesChange ||
      this.didLinkedInProfileChange ||
      this.didDefaultRatesChange
    );
  }

  @computed get didLocationChange(): boolean {
    return (
      this.location?.country !== this.data.location?.country ||
      this.location?.province !== this.data.location?.province ||
      this.location?.city !== this.data.location?.city
    );
  }

  @computed get didTimezoneChange(): boolean {
    return (
      this.timezone?.name !== this.data.timezone?.name ||
      this.timezone?.utcOffset !== this.data.timezone?.utcOffset
    );
  }

  @computed get didWorkingHoursChange(): boolean {
    return (
      JSON.stringify(this.workingHours) !==
      JSON.stringify(this.data.workingHours)
    );
  }

  @computed get didYearsExperienceChange(): boolean {
    return this.yearsOfExperience !== this.data.yearsOfExperience;
  }

  @computed get didPhoneChange(): boolean {
    return this.phone !== this.authStore.user?.phoneNumber;
  }

  @computed get didAboutMeChange(): boolean {
    return this.aboutMe !== this.data.aboutMe;
  }

  @computed get didWebsitesChange(): boolean {
    if (!this.websites.length && !this.data.websites?.length) return false;
    if (this.websites.length !== this.data.websites?.length) return true;
    return !!this.websites.find((website, i) => {
      return (
        this.data.websites &&
        this.data.websites[i] &&
        this.data.websites[i] !== website.url
      );
    });
  }

  @computed get didPersonalInfoChange(): boolean {
    return (
      this.didAboutMeChange ||
      this.didLocationChange ||
      this.didTimezoneChange ||
      this.didWorkingHoursChange ||
      this.didPhoneChange ||
      this.didWebsitesChange ||
      this.didLinkedInProfileChange ||
      this.didGithubProfileChange
    );
  }

  @computed get didPortfolioChange(): boolean {
    // if it didn't have a portfolio and the new fields are empty then it's not changed
    if (
      !this.data.portfolio &&
      !this.portfolio?.description &&
      !this.portfolio?.url
    ) {
      return false;
    }

    return !isEqual(this.portfolio, this.data.portfolio);
  }

  @computed get didJobsChange(): boolean {
    if (this.jobs.length !== this.data.jobs.length) return true;
    return !!this.jobs.find(
      (job) => isNewExperienceItem(job) || this.didJobChange(job),
    );
  }

  @computed get didProjectsChange(): boolean {
    if (this.localDeletedProjects.length || this.addedProjects.length)
      return true;
    return !!this.projects.find(
      (project) =>
        !isNewExperienceItem(project) && this.didProjectChange(project),
    );
  }

  @computed get didAvailabilityChange(): boolean {
    return (
      this.availability?.type !== this.data.availability?.type ||
      this.availability?.weeklyHoursAvailable !==
        this.data.availability?.weeklyHoursAvailable ||
      this.availability?.availableFrom !==
        this.data.availability?.availableFrom ||
      this.availability?.remindMeAt !== this.data.availability?.remindMeAt ||
      this.availability?.notes !== this.data.availability?.notes
    );
  }

  @computed get didDefaultRatesChange(): boolean {
    return (
      this.defaultRates?.hourlyRate !== this.data.defaultRates?.hourlyRate ||
      this.defaultRates?.monthlyRate !== this.data.defaultRates?.monthlyRate
    );
  }

  @computed get didMainSkillsChange(): boolean {
    if (
      this.mainSkills.length !==
      this.data.talentProfile?.talentSkills.mainTalentSkills.length
    )
      return true;
    return !!this.mainSkills.find((skill, i) => {
      return (
        this.data.talentProfile?.talentSkills.mainTalentSkills[i].rating !==
          skill.rating ||
        this.data.talentProfile?.talentSkills.mainTalentSkills[i]
          .talentSkillId !== skill.id
      );
    });
  }

  @computed get didAdditionalSKillsChange(): boolean {
    if (
      this.additionalSkills.length !==
      this.data.talentProfile?.talentSkills.additionalTalentSkills?.length
    )
      return true;
    return !!this.additionalSkills.find((skill, i) => {
      return (
        this.data.talentProfile?.talentSkills.additionalTalentSkills?.[i]
          .rating !== skill.rating ||
        this.data.talentProfile?.talentSkills.additionalTalentSkills?.[i]
          .talentSkillId !== skill.id
      );
    });
  }

  @computed get didSkillsChange(): boolean {
    return this.didMainSkillsChange || this.didAdditionalSKillsChange;
  }

  @computed get didRolesChange(): boolean {
    if (
      this.mainRole?.id !==
      this.data.talentProfile?.talentSpecializations?.mainTalentSpecialization
        ?.id
    ) {
      return true;
    }

    if (
      this.roles.length !==
      (this.data.talentProfile?.talentSpecializations
        ?.additionalTalentSpecializations.length || 0)
    ) {
      return true;
    }

    return !!this.roles.find((role, i) => {
      return (
        this.data.talentProfile?.talentSpecializations
          ?.additionalTalentSpecializations[i].id !== role.id
      );
    });
  }

  @computed get didIndustryExperiencesChange(): boolean {
    return (
      this.data.talentProfile?.talentIndustries?.experiences
        ?.map((e) => e.talentIndustryId)
        ?.join(',') !== this.industryExperiences.map((i) => i.id).join(',')
    );
  }

  @computed get didLinkedInProfileChange(): boolean {
    return this.linkedinUsername !== this.data.linkedIn?.username;
  }

  @computed get didGithubProfileChange(): boolean {
    return this.githubUsername !== this.data.github?.username;
  }

  @computed get eventsSource(): ProfileUpdateSource {
    return this.application
      ? ProfileUpdateSource.Application
      : ProfileUpdateSource.Profile;
  }

  @computed get generalTalentCategoryId(): string | undefined {
    return this.generalTalentCategory?.id;
  }

  @action setProfileMode = (mode: ProfileViewMode): void => {
    this.mode = mode;
  };

  @action refreshProfile = async (mode: ProfileViewMode): Promise<void> => {
    const user = await apiUsers.getUserByUsername(
      this.authStore,
      this.data.username,
    );
    this.setData(user);
    this.mode = mode;
  };

  @action setData = (data: UserObject): void => {
    this.data = data;
  };

  @action setInitialData = (): void => {
    this.setJobs(this.data.jobs);
    this.setProjects(this.data.projects);
    this.setInitialSkills(this.data.talentProfile?.talentSkills);
    this.setAvailability(this.data.availability);
    this.setInitialAvailabilityReminderPeriod();
    this.setLocation(this.data.location);
    this.setTimezone(this.data.timezone);
    this.setWorkingHours(this.data.workingHours);
    this.setYearsOfExperience(this.data.yearsOfExperience);
    this.setPhone(
      this.isCurrentUser
        ? this.authStore.user?.phoneNumber
        : this.data.phoneNumber,
    );
    this.setPortfolio(this.data.portfolio);
    this.setAboutMe(this.data.aboutMe);
    this.setInitialWebsites(this.data.websites);
    this.setInitialMainRole(
      this.data.talentProfile?.talentSpecializations?.mainTalentSpecialization,
    );
    this.setInitialRoles(
      this.data.talentProfile?.talentSpecializations
        ?.additionalTalentSpecializations,
    );
    this.setInitialIndustries(this.data?.talentProfile);
    this.setLinkedinUsername(this.data.linkedIn?.username);
    const defaultRates = {
      hourlyRate: this.data.defaultRates?.hourlyRate ?? 0,
      monthlyRate: this.data.defaultRates?.monthlyRate ?? 0,
    };
    this.defaultRates = defaultRates;
  };

  @action setInitialWebsites = (websites?: string[]): void => {
    this.setWebsites(
      (websites || []).map((website) => {
        return { _id: generateUniqueId(), url: website };
      }),
    );
  };

  @action setInitialIndustries = (
    talentProfile: TalentProfile | undefined,
  ): void => {
    const talentIndustries = talentProfile?.talentIndustries?.experiences ?? [];
    const industryExperiences = talentIndustries.map(
      ({ talentIndustryId, talentIndustryName }) => ({
        id: talentIndustryId,
        name: talentIndustryName,
        featured: true,
      }),
    );

    this.setIndustryExperiences(industryExperiences);
  };

  @action setInitialSkills = (
    skillsData?: UserTalentSkillAssignmentData,
  ): void => {
    if (!skillsData) return;

    this.setMainSkills(
      skillsData.mainTalentSkills.map((skill) => skillToExpertise(skill, true)),
    );
    this.setAdditionalSkills(
      skillsData.additionalTalentSkills?.map((skill) =>
        skillToExpertise(skill),
      ) ?? [],
    );
  };

  @action setInitialMainRole = (role?: TalentSpecialization): void => {
    if (!role) return;
    this.setMainRole(roleToExpertise(role, true));
  };

  @action setIndustryExperiences = (experiences: Expertise[]): void => {
    this.industryExperiences = experiences;
  };

  @action setInitialRoles = (rolesData?: TalentSpecialization[]): void => {
    if (!rolesData) return;

    this.setAdditionalRoles(
      rolesData.map((role) => roleToExpertise(role, true)),
    );
  };

  @action setInitialAvailabilityReminderPeriod = (): void => {
    if (!this.availability) return;
    const { type, updatedAt, remindMeAt } = this.availability;

    if (type === AvailableType.NotAvailable) {
      this.setSelectedAvailabilityReminderPeriod(
        updatedAt && remindMeAt
          ? getReminderPeriod(new Date(updatedAt), new Date(remindMeAt))
          : ReminderPeriod.OneMonth,
      );
    }
  };

  @action setProfileImage = (profilePictureURL: string): void => {
    this.data.profilePictureURL = profilePictureURL;
    const updatedUser = {
      ...this.authStore.user,
      profilePictureURL,
    } as CurrentUserObject;
    this.authStore.setUser(updatedUser);
  };

  @action setMainRole = (role: Expertise): void => {
    this.mainRole = role;

    if (this.roles.map((role) => role.id).includes(role.id)) {
      this.roles = this.roles.filter(
        (additionalRoles) => additionalRoles.id !== role.id,
      );
    }
  };

  @action setYearsOfExperience = (years: number | undefined): void => {
    this.yearsOfExperience = years;
  };

  @action setMainSkills = (skills: Expertise[]): void => {
    this.skills.main = skills;
  };

  @action setAdditionalSkills = (skills: Expertise[]): void => {
    this.skills.additional = skills;
  };

  @action saveSkillsAsMain = async (
    skillsToUpdate: Expertise[],
  ): Promise<void> => {
    const skillsToPersist = [...(this.allSkills || [])];

    for (const skill of skillsToUpdate) {
      const found = skillsToPersist.find((s) => s.id === skill.id);
      if (found) {
        found.rating = skill.rating;
        found.featured = true;
      } else {
        skillsToPersist.push({ ...skill, featured: true });
      }
    }

    this?.updateSkills(skillsToPersist);
    await this?.saveSkills();
    await this.authStore.fetchData(true);
  };

  @action updateSkills = (skills: Expertise[]): void => {
    this.setMainSkills(
      skills.filter((skill) => skill.rating && skill.featured),
    );
    this.setAdditionalSkills(
      skills.filter((skill) => skill.rating && !skill.featured),
    );
  };

  @action setAdditionalRoles = (roles: Expertise[]): void => {
    this.roles = roles;

    if (
      this.mainRole?.id &&
      roles.map((role) => role.id).includes(this.mainRole?.id)
    ) {
      this.mainRole = undefined;
    }
  };

  @action addSingleAdditionalRole = (role: Expertise): void => {
    this.roles = [...this.roles, role];
  };

  @action removeSingleAdditionalRole = (roleId: Expertise['id']): void => {
    this.roles = this.roles.filter(
      (existingRole) => existingRole.id !== roleId,
    );
  };

  @action setWebsites = (websites: LocalWebsites): void => {
    this.websites = websites;
  };

  @action setJobs = (jobs: LocalJobs): void => {
    this.jobs = sortBy(
      [...jobs],
      (job) => new Date(job.startDate ?? 0).getTime() * -1,
    );
  };

  @action addCompanyJob = (
    company: Company & { url?: string },
    role: string,
    skills: string[],
    specialization?: string,
    startDate?: string,
    endDate?: string,
    jobToUpdate?: NewJob | ExistingJob,
  ): void => {
    if (!jobToUpdate || jobToUpdate.isSuggested) {
      const newJob: NewJob = {
        ...this.getEmptyJob(),
        jobRole: role,
        companyV2Id: company.id === NewCompanyId ? null : company.id,
        websiteURL: company.url,
        companyName: company.name,
        applicableTalentSkills: skills.map((talentSkillId) => ({
          talentSkillId,
        })),
        applicableTalentSpecialization: specialization
          ? { talentSpecializationId: specialization }
          : undefined,
        startDate,
        endDate,
        // todo: remove this when only enriched companies are linked
        name: company.name,
      };

      this.setJobs([newJob, ...this.jobs]);
      this.analytics.trackProfileJobsAddClicked(
        this.eventsSource,
        this.missionsStore?.currentMission?.data,
        this.application?.currentRole,
        this.application?.aid,
      );

      if (jobToUpdate?.isSuggested) {
        this.createJob(newJob);
      }
    } else {
      const existingJobIndex = this.jobs.findIndex(
        ({ name, jobRole, type }) =>
          jobToUpdate.name === name &&
          jobToUpdate.jobRole === jobRole &&
          jobToUpdate.type === type,
      );

      if (existingJobIndex > -1) {
        this.jobs[existingJobIndex] = {
          ...this.jobs[existingJobIndex],
          name: company.name,
          jobRole: role,
          companyV2Id: company.id === NewCompanyId ? null : company.id,
          websiteURL: company.url,
          companyName: company.name,

          applicableTalentSkills: skills.map((talentSkillId) => ({
            talentSkillId,
          })),
          applicableTalentSpecialization: specialization
            ? { talentSpecializationId: specialization }
            : undefined,
          startDate,
          endDate,
        };
        this.setJobs(this.jobs);
      }
    }
  };

  @action addEmptyProject = (): (ExistingProject | NewProject)[] => {
    const projects = [this.getEmptyProject(), ...this.projects];
    this.setProjects(projects);

    this.analytics.trackProfileProjectAddClicked(
      this.eventsSource,
      this.missionsStore?.currentMission?.data,
      this.application?.currentRole,
      this.application?.aid,
    );
    return projects;
  };

  @action addEmptyWebsite = (): void => {
    this.setWebsites([this.getEmptyWebsite(), ...this.websites]);
  };

  @action setHighlightedAdminNoteId = (id: string | undefined): void => {
    this.highlightedAdminNoteId = id;
  };

  @action setLocation = (location?: LocationObject): void => {
    this.location = location;
    const { user } = this.authStore;
    if (user) {
      user.location = location;
    }
  };

  @action setTimezone = (timezone?: TimezoneObject): void => {
    this.timezone = timezone;
    if (this.workingHours && timezone) {
      this.workingHours.name = timezone.name;
      this.workingHours.utcOffset = timezone.utcOffset;
    }
  };

  @action setWorkingHours = (workingHours?: WorkingHoursSchema): void => {
    this.workingHours = workingHours;
  };

  @action setPortfolio = (portfolio?: PortfolioObject): void => {
    this.portfolio = portfolio;
  };

  @action setPhone = (phone?: string): void => {
    this.phone = phone;
  };

  @action setAboutMe = (text: string | undefined): void => {
    this.aboutMe = text;
  };

  @action setLinkedinUsername = (username: string | undefined): void => {
    this.linkedinUsername = username;
  };

  @action setGithubUsername = (username: string | undefined): void => {
    this.githubUsername = username;
  };

  @action setDefaultRates = (rates: DefaultRates | undefined): void => {
    this.defaultRates = rates;
  };

  @action setOriginalProjects = (projects: ExistingProject[]): void => {
    this.data.projects = projects;
  };

  @action setProjects = (projects: LocalProjects): void => {
    this.projects = projects;
  };

  @action setAvailability = (
    availability?: AvailabilitySummaryObject,
  ): void => {
    this.availability = availability;
    const { user } = this.authStore;
    if (user) {
      user.availability = availability;
    }
  };

  @action setSelectedAvailabilityReminderPeriod = (
    period: ReminderPeriod,
  ): void => {
    this.availabilityReminderPeriod = period;
  };

  @action setAvailabilityReminderPeriod = (period: ReminderPeriod): void => {
    const today = setHours(new Date(), 0).setMinutes(0);
    this.setSelectedAvailabilityReminderPeriod(period);

    let months;
    switch (period) {
      case ReminderPeriod.OneMonth:
        months = 1;
        break;

      case ReminderPeriod.ThreeMonths:
        months = 3;
        break;

      case ReminderPeriod.SixMonths:
        months = 6;
        break;
    }

    this.setAvailability({
      type: this.availabilityType,
      remindMeAt: add(today, { months }).toISOString(),
      keepNotifications: this.keepNotifications,
    });
  };

  @action public setIsVetter = (isVetter: boolean | undefined): void => {
    this.data.isVetter = isVetter;
    this.adminNotes?.setSelectionTeam(!!isVetter);

    this.data.badges = this.data.badges.filter(
      (badge) => badge !== UserBadge.SelectionTeam,
    );

    if (isVetter) {
      this.data.badges = [...this.data.badges, UserBadge.SelectionTeam];
    }
  };

  @action public setVettingScheduled = (vettingScheduled: boolean): void => {
    this.adminNotes?.setVettingScheduled(vettingScheduled);
  };

  checkApplicationRequirements = async (): Promise<void> => {
    if (!this.allFieldsValid) {
      this.setShowErrors(true);
      return Promise.reject(null);
    }
    if (this.application?.shouldShowRequirementsMissingWarning) {
      this.setShowErrors(true);
      this.application.setRequirementsModalOpen(true);
      return;
    }
    return this.saveApplication();
  };

  @action public setConnections = (
    connections: BasicConnectionObjectV2[],
  ): void => {
    this.data.connections = connections;
  };

  @action setShowErrors = (show: boolean): void => {
    this.showErrors = show;
  };

  @action setShowConfirmSubmitEdits = (confirm: boolean): void => {
    this.showConfirmSubmitEdits = confirm;
  };

  @action saveProfileEditsSubmitLater = (): void => {
    this.submitEditsLater = true;
    this.saveProfile();
  };

  @action saveProfileAndSubmit = (): void => {
    this.submitEditsLater = false;
    this.saveProfile();
  };

  @action setApplication(
    applicationDetails: ApplicationDetails | null,
    applicationData?: MissionApplicationObject | MissionApplicationDraftObject,
    mode?: ApplicationViewMode,
  ): void {
    if (!applicationDetails || !applicationData) {
      this.application = undefined;
      return;
    }

    this.application = new Application(
      applicationDetails,
      applicationData,
      this.authStore,
      this.analytics,
      this.missionsStore,
      this,
      mode,
    );
    this.initializeHiddenProjects();

    const shouldTrackApplicationStarted =
      this.data.uid === this.application?.data.user.uid &&
      mode === ApplicationViewMode.Edit;
    if (this.application.currentRole && shouldTrackApplicationStarted) {
      this.analytics.trackMissionApplyStarted({
        ...(this.missionsStore?.currentMission?.data as MissionObject),
        role: this.application.currentRole,
      });
    }
  }

  public fetchAdminNotes = async (): Promise<void> => {
    const adminNotes = await apiUsers.adminGetNotes(
      this.authStore,
      this.data.username,
    );

    this.setAdminNotes(adminNotes);
  };

  @action setAdminNotes(adminNotes: AdminNotesObject | null): void {
    if (!adminNotes) {
      this.adminNotes = undefined;
      return;
    }
    this.adminNotes = new AdminNotes(
      adminNotes,
      this.data.badges.includes(UserBadge.SelectionTeam),
      this.data.scrubbed,
      this.adminNotes?.vettingScheduled ??
        (this.data.manualVettingProcess || false),
      this.authStore,
      this.data.badges.includes(UserBadge.BeenOnMission),
    );
    this.setVetterConfiguration(adminNotes.vetterConfiguration);
  }

  @action setVetterConfiguration(vetter?: VetterConfigurationObject): void {
    if (!vetter) {
      this.vetterConfiguration = vetter;

      return;
    }

    this.vetterConfiguration = new VetterConfiguration(this.authStore, vetter);
  }

  updateVetterConfiguration = async (): Promise<void> => {
    if (this.vetterConfiguration) {
      await this.vetterConfiguration.updateVetter(
        this.vetterConfiguration.updateVetterPayload,
      );
    }
  };

  @action setUserScrubbed = (scrubbed: UserScrubbed): void => {
    this.data.scrubbed = scrubbed;
  };

  @action setUserCustomTags = (tags: CustomUserTagObject[]) => {
    this.data.customTags = tags;
  };

  @action setMarkedAsRejected = (rejected: boolean): void => {
    this.adminNotes?.setMarkedAsRejected(rejected);
  };

  @action setGeneralTalentCategory = (
    generalTalentCategory: TalentCategory,
  ): void => {
    this.generalTalentCategory = generalTalentCategory;
  };

  @action setProfileUpdatedAt = (
    date?: Date | null,
  ): Date | null | undefined => {
    this.profileUpdatedAt = date;
    return this.profileUpdatedAt;
  };

  @action setInviterUser = (user: AdminBasicUserObject): void => {
    this.data.invitedBy = user;
  };

  @action removeUserCv = async (): Promise<void> => {
    await apiUser.updateCV(this.authStore, { cvURL: undefined });
    this.data.cvURL = undefined;
  };

  @action updateUserCv = (cvURL: string): void => {
    this.data.cvURL = cvURL;
  };

  @action updateDefaultHourlyRate = (rate: number): void => {
    const newRates: DefaultRates = {
      hourlyRate: rate,
      monthlyRate: this.defaultRates?.monthlyRate ?? 0,
    };
    this.defaultRates = newRates;
  };

  @action updateDefaultMonthlyRate = (rate: number): void => {
    const newRates: DefaultRates = {
      hourlyRate: this.defaultRates?.hourlyRate ?? 0,
      monthlyRate: rate,
    };
    this.defaultRates = newRates;
  };

  @action initializeHiddenProjects = (): void => {
    // we filter out all hidden projects that were deleted
    const hiddenProjects =
      this.application?.applicationData.hiddenProjects?.filter((id) =>
        this.displayedProjectIds.includes(id),
      ) ?? [];

    const localHiddenProjects =
      hiddenProjects.length === 0
        ? this.mode === ProfileViewMode.Edit
          ? new Map(this.displayedProjectIds.map((id) => [id, true]) || [])
          : new Map()
        : this.localHiddenAndSelectedProjects.hidden;

    localHiddenProjects.forEach((_, projectId) => {
      this.application?.setHiddenProject(projectId, true);
    });
  };

  @computed get isMarkedAsRejected(): boolean {
    return (
      this.adminNotes?.isMarkedAsRejected ||
      this.data.status === UserStatus.Rejected
    );
  }
  @computed get allIndustries(): Expertise[] {
    let arr: Expertise[] = [];
    this?.queryIndustries('')
      .then((resp) => {
        arr = resp;
      })
      .catch((err) => console.error(err));
    return arr;
  }

  @computed get applicationAnalysis():
    | MissionApplicationAnalysisObject
    | undefined {
    return this.application?.data.analysis;
  }

  @computed get userCV(): UserCV {
    return {
      label: `${this.data.firstName}'s Resume`,
      downloadUrl: this.data.cvURL,
    };
  }
}
