import React, { memo, ReactElement, useEffect, useMemo } from 'react';
import { match as Match, Redirect, Route, useHistory } from 'react-router-dom';
import { RouteComponentProps, useParams } from 'react-router';
import { Location } from 'history';
import { Observer } from 'mobx-react';
import { Page, useAnalytics } from '@ateams/analytics/dist/platform';
import {
  LocationPath,
  LocationTemplate,
  MissionControlBase,
  MissionPageLocationTemplate,
  ProfileLocation,
  SignInLocation,
} from '@src/locations';
import { Stores, useStores } from '@src/stores';
import ErrorView from '@src/views/Error';
import { AuthMatcher, authorizeRoute } from './authorization';
import { REGISTRATION_BASE_URL } from '@src/config';
import { identifyHotjarUser } from '@src/helpers/hotjar';

export interface ApiError extends Error {
  code?: number;
  statusCode?: number;
  skipLog?: boolean;
  payload?: unknown;
}

export type ViewProps<
  // eslint-disable-next-line @typescript-eslint/ban-types
  Params extends { [K in keyof Params]?: string } = {},
> = RouteComponentProps<Params>;

export interface ServerSideLoader<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Params extends { [K in keyof Params]?: string } = any,
> {
  (stores: Stores, match: Match<Params>, location: Location): Promise<unknown>;
}

export interface CustomRouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<any>;
  serverSideLoader?: ServerSideLoader;
  path?: LocationTemplate | LocationPath;
  redirect?: string;
  redirectParams?: boolean;
  exact?: boolean;
  withAuth: boolean | AuthMatcher;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  computedMatch?: Match<any>;
  location?: Location;
  immediate?: boolean;
  name: Page;
  loadUser?: boolean;
}

const isEqual = (
  prevProps: CustomRouteProps,
  nextProps: CustomRouteProps,
): boolean => {
  if (prevProps.computedMatch) {
    return prevProps.computedMatch?.url === nextProps.computedMatch?.url;
  }
  return false;
};

const CustomRoute = memo((props: CustomRouteProps): ReactElement => {
  const {
    path,
    redirect,
    redirectParams,
    withAuth,
    component: Component,
    serverSideLoader,
    computedMatch,
    location,
    immediate,
    name,
    ...leftProps
  } = props;
  const stores = useStores();
  const analytics = useAnalytics();
  const params = useParams();
  const history = useHistory();
  const { auth, error } = stores;

  const redirectUrl = history.location.pathname + history.location.search;

  if (auth.newOnboardingFlow && !!auth.onboardingLocation) {
    window.location.href = `${REGISTRATION_BASE_URL}${auth.onboardingLocation}`;
  }

  const authRedirect = authorizeRoute(withAuth, auth, redirectUrl, path);

  const redirectToMissionControl = () => {
    history.push(MissionControlBase);
  };

  const handleLoaderError = (e: ApiError) => {
    const isMissionPermissionError =
      e.code === 403 && path?.startsWith(MissionPageLocationTemplate);
    return isMissionPermissionError
      ? redirectToMissionControl()
      : stores.error.handleError(e);
  };

  const runServerSideLoader =
    !authRedirect && !redirect && !!immediate && !error.error;

  useEffect(() => {
    if (
      !runServerSideLoader ||
      !serverSideLoader ||
      !computedMatch ||
      !location
    )
      return;

    serverSideLoader(stores, computedMatch, location).catch(handleLoaderError);
  }, [runServerSideLoader, serverSideLoader, computedMatch, stores, location]);

  const component = useMemo(() => {
    return (props: RouteComponentProps) => {
      return error.error ? (
        <Observer>{() => <ErrorView {...props} />}</Observer>
      ) : Component ? (
        // TODO: remove this hack
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Observer>{() => <Component {...props} />}</Observer>
      ) : null;
    };
  }, [props, error]);

  useEffect(() => {
    const shouldRedirect = authRedirect || redirect;
    if (!shouldRedirect) {
      if (auth.uid) {
        identifyHotjarUser(auth.uid, {
          email: auth.email || undefined,
          fullName: auth.currentUser?.fullName,
          username: auth.username || undefined,
          isAdmin: auth.isAdmin,
        });

        analytics.identifyUser(
          {
            uid: auth.uid,
            email: auth.email || undefined,
            fullName: auth.currentUser?.fullName,
            username: auth.username,
            isAdmin: auth.isAdmin,
            createdAt:
              auth.currentUser && 'createdAt' in auth.currentUser
                ? auth.currentUser.createdAt
                : undefined,
            scrubbedAt:
              auth.currentUser && 'initialReviewDate' in auth.currentUser
                ? auth.currentUser.initialReviewDate
                : undefined,
            tags:
              auth.currentUser &&
              'customTags' in auth.currentUser &&
              auth.currentUser.customTags?.length
                ? auth.currentUser.customTags?.map((tag) => tag.name)
                : undefined,
          },
          false,
        );
      }
      analytics.page({
        name,
        properties: params,
      });
    }
  }, [authRedirect, redirect, params]);

  if (authRedirect) {
    const authRedirectTo =
      authRedirect !== path
        ? authRedirect
        : auth.username &&
          authRedirect !== ProfileLocation(auth.username as string)
        ? ProfileLocation(auth.username as string)
        : SignInLocation;

    return (
      <Observer>
        {() => <Redirect from={path} to={authRedirectTo} {...leftProps} />}
      </Observer>
    );
  }

  if (redirect) {
    return (
      <Observer>
        {() => (
          <Redirect
            from={path}
            to={
              redirect + (redirectParams && !!location ? location?.search : '')
            }
            exact={true}
            {...leftProps}
          />
        )}
      </Observer>
    );
  }

  return <Observer>{() => <Route path={path} render={component} />}</Observer>;
}, isEqual);

export default CustomRoute;
