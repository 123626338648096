import './wdyr';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import React, { ComponentType, Fragment, ReactElement } from 'react';
import ReactDOM from 'react-dom';
import jsCookie from 'js-cookie';
import { createBrowserHistory } from 'history';

import {
  QueryClientProvider,
  QueryClient,
  hydrate,
} from '@tanstack/react-query';

import './theme/base.css';
import App, { AppProps } from './App';

import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router';
import { createStores, StoresData, Provider, fetchStoreData } from './stores';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import {
  IS_PROD_BUILD,
  SEGMENT_WRITE_KEY,
  SENTRY_DSN,
  SENTRY_ENABLED,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE,
} from './config';
import { createConsoleLoggerMock } from '@ateams/analytics/dist/common/createConsoleLoggerMock';
import { replayEventLog } from '@ateams/analytics/dist/common/replayEventLog';
import { EventLogItem } from '@ateams/analytics/dist/common/types';
import {
  setupSegment,
  createPlatformServiceBrowserAnalytics,
  PlatformServiceAnalytics,
  PlatformServiceAnalyticsContext,
} from '@ateams/analytics/dist/platform';
import { ThemeProvider } from '@emotion/react';
import { theme } from '@a_team/ui-components/lib/theme';
import { initHotjar } from './helpers/hotjar';

initHotjar({
  debug: !IS_PROD_BUILD,
  siteId: 2683896,
  version: 6,
});

Sentry.init({
  dsn: SENTRY_DSN,
  release: SENTRY_RELEASE,
  enabled: SENTRY_ENABLED,
  environment: SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  sampleRate: 1.0,
  tracesSampleRate: 0.01,
});

interface WindowWithStaticContext extends Window {
  SERVER_STORES?: StoresData;
  SERVER_QUERY_CLIENT?: unknown;
  SERVER_SC?: AppProps['fallbackScreenClass'];
  EVENTS?: EventLogItem[];
}

declare let window: WindowWithStaticContext;

let analytics: PlatformServiceAnalytics;
if (IS_PROD_BUILD && window.location.hostname !== 'localhost') {
  setupSegment(SEGMENT_WRITE_KEY);
  analytics = createPlatformServiceBrowserAnalytics();
} else {
  analytics = createConsoleLoggerMock('analytics');
}

// Replay events that occurred during SSR
if (window.EVENTS) {
  replayEventLog(analytics, window.EVENTS);
}

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();
const history = syncHistoryWithStore(browserHistory, routingStore);

const { SERVER_STORES, SERVER_QUERY_CLIENT, SERVER_SC } = window;
const stores = createStores(jsCookie, analytics, SERVER_STORES || {});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
if (SERVER_QUERY_CLIENT) {
  hydrate(queryClient, SERVER_QUERY_CLIENT);
}

function Main({ app: App }: { app: ComponentType<AppProps> }): ReactElement {
  React.useEffect((): void => {
    fetchStoreData(stores).catch((err) => console.error(err));

    const jssStyles = document.getElementById('jss-server-side');

    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <PlatformServiceAnalyticsContext.Provider value={analytics}>
      <QueryClientProvider client={queryClient}>
        <Provider stores={stores}>
          <Router history={history}>
            {/* TODO: Remove this hack */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Sentry.ErrorBoundary fallback={'An error has occurred'}>
              <Fragment>
                <ThemeProvider theme={theme}>
                  {/* TODO: Remove this hack */}
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <App fallbackScreenClass={SERVER_SC} />
                </ThemeProvider>
              </Fragment>
            </Sentry.ErrorBoundary>
          </Router>
        </Provider>
      </QueryClientProvider>
    </PlatformServiceAnalyticsContext.Provider>
  );
}

function render(App: ComponentType<AppProps>): void {
  ReactDOM[!IS_PROD_BUILD && module.hot ? 'render' : 'hydrate'](
    <Main app={App} />,
    document.getElementById('root'),
  );
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let module: NodeModule & { hot: any };

if (!IS_PROD_BUILD && module.hot) {
  module.hot.accept('./App', (): void => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NewApp = require('./App').default;
    render(NewApp);
  });
}
